import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

import { selectFolderById, selectReportsByMultiReportId, useGetMultiProjectQuery, useGetMultiReportQuery, useGetReportsQuery } from "fond/api";
import MapProvider from "fond/map/MapProvider";
import { loadMultiProject } from "fond/project/redux";
import CityPlannerFolderMap from "fond/projects/CityPlanner/CityPlannerFolderMap";
import CityReportList from "fond/projects/CityReport/CityReportList";
import { Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import SkeletonLoadingGrid from "fond/widgets/SkeletonLoadingGrid";

interface RouteParams {
  folderId: string;
}

/**
 * A main page content displaying a city planner report.
 * Includes a DataGrid supporting column sorting and pagination
 */
const CityReportFolderPageContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { folderId } = useParams<keyof RouteParams>();
  const folder = useSelector((state: Store) => (folderId ? selectFolderById(state, folderId) : undefined));
  const multiReportId = folder?.MultiReport?.ID;
  const { data: multiReport } = useGetMultiReportQuery(multiReportId ?? skipToken);
  const { data: multiProject } = useGetMultiProjectQuery(multiReport?.MultiProject?.ID ?? skipToken);
  const { isLoading: isReportsLoading } = useGetReportsQuery(multiReportId ?? skipToken);
  const reports = useSelector((state: Store) => (multiReport && selectReportsByMultiReportId(state, multiReport.ID)) || []);
  const [multiProjectloaded, setMultiProjectloaded] = useState(false);

  // Keep polling until all subarea reports are in a terminal state.
  const allFinished = reports?.every((report) => ["COMPLETE", "ERROR"].includes(report?.Status?.State ?? "")) ?? false;
  useGetReportsQuery(multiReportId ?? skipToken, { pollingInterval: allFinished ? 0 : 30_000 });

  useEffect(() => {
    if (multiProject?.ID) {
      dispatch(loadMultiProject({ uuid: multiProject?.ID })).then(async () => {
        setMultiProjectloaded(true);
      });
    }
  }, [dispatch, multiProject?.ID, folderId]);

  if (!multiProjectloaded && (!multiReport || isReportsLoading))
    return (
      <SkeletonLoadingGrid
        items={[
          { height: 300, sizes: { xs: 12, sm: 12 } },
          { height: 300, sizes: { xs: 12, sm: 12 } },
        ]}
      />
    );

  // TODO: Replace this with an actual report map styled by Npv.
  return (
    <>
      <Typography>{multiReport?.Description}</Typography>
      {multiProjectloaded && (
        <Box mt={2} width="100%" height={246} position="relative" borderRadius={1} overflow="hidden">
          <MapProvider mapStyle="monochrome">{multiProject?.ID && <CityPlannerFolderMap cityId={multiProject?.ID} />}</MapProvider>
        </Box>
      )}

      <CityReportList />
    </>
  );
};

export default CityReportFolderPageContent;
