import React, { useCallback, useEffect, useRef, useState } from "react";
import { GridApi, GridReadyEvent, IRowNode, PostSortRowsParams, SelectionChangedEvent } from "@ag-grid-community/core";
import { Share as ShareIcon } from "@mui/icons-material";
import { Box, Button, Toolbar, Tooltip } from "@mui/material";
import { useDebounce } from "use-debounce";

import mixpanel from "fond/mixpanel";
import Share from "fond/share/Share";
import { BaseMultiProject, Folder, Project, ProjectPreview, Report, ResourceEntity } from "fond/types";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { AgGrid, BlockSpinner } from "fond/widgets";

import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import CityPlannerRowMenu from "../ItemMenu/CityPlannerRowMenu";
import FolderRowMenu from "../ItemMenu/FolderRowMenu";
import ProjectRowMenu from "../ItemMenu/ProjectRowMenu";
import ReportRowMenu from "../ItemMenu/ReportRowMenu";
import useProjectsTable from "../useProjectsTable";

import ProjectListToolbar from "./ProjectListToolbar";

interface IProps {
  isLoading?: boolean;
  items: Array<BaseMultiProject | ProjectPreview | Folder | Report> | null;
  folder?: Folder;
  searchText: string;
}

const ProjectList: React.FC<IProps> = ({ folder, isLoading = false, items, searchText }: IProps) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<Element | undefined>(undefined);
  const [menuEntity, setMenuEntity] = useState<ResourceEntity | undefined>(undefined);
  const [selectedItems, setSelectedItems] = useState<Array<Folder | Project>>([]);
  const gridApi = useRef<GridApi<Folder | Project> | null>();
  const filter = React.useRef(searchText);
  const [debouncedSearch] = useDebounce(searchText, 500);

  useEffect(() => {
    filter.current = searchText;
  }, [searchText]);

  useEffect(() => {
    /*
     * This event is being captured here instead in a parent component as we are interested in the size of
     * the project list being rendered for performance purposes. We may change this in future.
     */
    mixpanel.track("Entered project list page", { projectCount: items?.length });
  }, [items?.length]);

  const toggleShareModal = () => {
    setShowShareModal(!showShareModal);
  };

  /**
   * Callback function called when row selection changes within the grid.
   */
  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData: Array<Folder | Project> = [];
    selectedNodes.forEach((node) => {
      if (node.data) selectedData.push(node.data);
    });
    setSelectedItems(selectedData);
  };

  const doesExternalFilterPass = (node: IRowNode<Folder | Project | Report>): boolean => {
    if (node.data?.EntityType === "project") {
      return node.data.ProjectName.toUpperCase().includes(filter.current) || node.data.Creator.toUpperCase().includes(filter.current);
    } else if (node.data && ["folder", "report"].includes(node.data?.EntityType)) {
      return node.data.Name.toUpperCase().includes(filter.current) || !!node.data.Description?.toUpperCase().includes(filter.current);
    }
    return filter.current === "";
  };

  /**
   * We always keep the Folder entity type at the top of the grid.
   * Note: We exclude MultiProjects from this sorting, even though they are a type of folder.
   */
  const postSortRows = (params: PostSortRowsParams<Folder | Project>) => {
    let rowNodes = params.nodes;
    // here we put Folder rows on top while preserving the sort order
    let nextInsertPos = 0;
    for (let i = 0; i < rowNodes.length; i += 1) {
      const type = rowNodes[i].data?.EntityType;
      const isMultiProject = type === "folder" && (rowNodes[i].data as Folder | undefined)?.MultiProject;
      if (type === "folder" && !isMultiProject) {
        rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
        nextInsertPos += 1;
      }
    }
  };

  const isRowSelectable = (rowNode: IRowNode<Folder | Project>) => rowNode.data?.EntityType !== "folder";

  /**
   * Callback function called when the AgGrid has initialised
   */
  const handleOnGridReady = (event: GridReadyEvent) => {
    gridApi.current = event.api;
  };

  /**
   * Callback function that handles opening the row menu
   */
  const openRowMenu = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, entity?: ResourceEntity) => {
    setMenuAnchor(e.currentTarget);
    setMenuEntity(entity);
  }, []);

  const { columns } = useProjectsTable({ openRowMenu });

  /**
   * Callback function that handles closing the row menu
   */
  const closeRowMenu = () => setMenuAnchor(undefined);

  const handleOnClear = () => {
    gridApi.current?.deselectAll();
    setSelectedItems([]);
  };

  return (
    <>
      <Box className="project-list" data-testid="project-list" sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <Toolbar>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            {selectedItems.length > 0 ? (
              <ProjectListToolbar selectedItems={selectedItems} onComplete={handleOnClear} onClear={handleOnClear} />
            ) : (
              <>
                <Breadcrumbs />
                {folder?.ID && permissionCheck(folder.Permission.Level, Actions.FOLDER_SHARE_VIEW) && (
                  <Tooltip title={`Share ${folder.Name}`}>
                    <Button
                      data-testid="share-button"
                      onClick={toggleShareModal}
                      size="small"
                      color="primary"
                      variant="outlined"
                      startIcon={<ShareIcon />}
                    >
                      Share
                    </Button>
                  </Tooltip>
                )}
              </>
            )}
          </Box>
        </Toolbar>

        <AgGrid
          autoSizeColumns={false}
          containerProps={{ flexGrow: 1 }}
          rowData={items}
          gridOptions={{
            defaultColDef: {
              resizable: true,
              suppressHeaderMenuButton: true,
              suppressMovable: true,
              sortable: true,
            },
            domLayout: "autoHeight",
            rowClass: "no-alternate-background",
            animateRows: true,
            suppressGroupRowsSticky: true,
            suppressRowClickSelection: true,
            suppressContextMenu: true,
            sideBar: false,
            rowGroupPanelShow: "never",
            pagination: true,
            paginationPageSize: 50,
            postSortRows: postSortRows,
            isRowSelectable: isRowSelectable,
            loadingOverlayComponent: BlockSpinner,
          }}
          externalFilter
          externalSearchText={debouncedSearch}
          doesExternalFilterPass={doesExternalFilterPass}
          columnDefs={columns}
          onSelectionChanged={onSelectionChanged}
          onGridReady={handleOnGridReady}
          showLoadingOverlay={isLoading}
          size="large"
        />
      </Box>
      {showShareModal && folder?.ID && <Share resource={folder} onClose={() => toggleShareModal()} />}
      {menuEntity?.EntityType === "project" && <ProjectRowMenu projectPreview={menuEntity} onMenuClose={closeRowMenu} anchorEl={menuAnchor} />}
      {menuEntity?.EntityType === "folder" &&
        (menuEntity.MultiProject ? (
          <CityPlannerRowMenu cityPlannerFolder={menuEntity} onMenuClose={closeRowMenu} anchorEl={menuAnchor} />
        ) : (
          <FolderRowMenu folder={menuEntity} onMenuClose={closeRowMenu} anchorEl={menuAnchor} />
        ))}
      {menuEntity?.EntityType === "report" && <ReportRowMenu report={menuEntity} onMenuClose={closeRowMenu} anchorEl={menuAnchor} />}
    </>
  );
};

export default ProjectList;
