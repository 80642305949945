import React from "react";
import { LinearProgress, Tooltip } from "@mui/material";

import { TableCell, TableRow } from "ui";

import { SliderField } from "fond/form/fields";
import { MultiProject } from "fond/types";

interface ScoringInputProps {
  config?: MultiProject["AssessConfiguration"] | null;
}

type Input = { id: "AddressDensityImportance" | "AddressUnderservedProportionImportance"; title: string; description: string };

const inputs: Input[] = [
  {
    id: "AddressDensityImportance",
    title: "Street Frontage",
    description: "Subarea score will increase as average street frontage decreases",
  },
  {
    id: "AddressUnderservedProportionImportance",
    title: "Proportion underserved",
    description: "Subarea score will increase as the underserved proportion increases",
  },
];

const ScoringInputs: React.FC<ScoringInputProps> = ({ config }: ScoringInputProps) => {
  const getInput = ({ id, title, description }: Input) => (
    <TableRow key={id}>
      <TableCell>
        <Tooltip title={description} placement="right-start">
          <span>{title}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        {config === undefined ? (
          <SliderField
            name={id}
            size="small"
            min={0}
            max={1}
            step={0.01}
            sx={{ width: 75, marginLeft: 1, marginRight: 1 }}
            track={false}
            valueLabelDisplay="auto"
          />
        ) : (
          <LinearProgress variant="determinate" value={config === null ? 50 : config[id] * 100} />
        )}
      </TableCell>
    </TableRow>
  );

  return inputs.map(getInput);
};

export default ScoringInputs;
