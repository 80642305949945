import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router";
import LocationCityIcon from "@mui/icons-material/LocationCityOutlined";
import { Box, Typography } from "@mui/material";

import { selectFolderById, useGetFoldersQuery } from "fond/api";
import { Navigation } from "fond/projects";
import { Store } from "fond/types";
import { PageContent } from "fond/widgets";

import CityReportFolderPageContent from "./CityReportFolderPageContent";

interface RouteParams {
  folderId: string;
}

/**
 * A top level component used for displaying a city planner report folder.
 */
const CityReportFolder: React.FC = () => {
  const { isFetching: isFoldersFetching } = useGetFoldersQuery(undefined);
  const { folderId } = useParams<keyof RouteParams>();

  const currentFolder = useSelector((state: Store) => (folderId ? selectFolderById(state, folderId) : undefined));
  const folderName = currentFolder?.MultiReport?.Name ?? "";

  /**
   * If folderId is not null but currentFolder is null, it indicates that the current user has no access to the multi project,
   * redirect to projects landing page.
   */
  if (folderId && !currentFolder && !isFoldersFetching) {
    return <Navigate to="/workspace/" />;
  }

  return (
    <PageContent
      windowTitle={folderName}
      title={
        <Box display="flex" alignItems="center">
          <LocationCityIcon color="tertiary" sx={{ mr: 1 }} />
          <Typography variant="h1" data-testid="page-title">
            {folderName}
          </Typography>
        </Box>
      }
      mainContent={<CityReportFolderPageContent />}
      navigation={<Navigation />}
      testId="multi-report-content"
    />
  );
};

export default CityReportFolder;
