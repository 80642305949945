import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { usePrevious } from "react-use";
import { Box } from "@mui/material";

import {
  injectIntoVersionConfig,
  selectAllVersionGroupConfigs,
  selectAllVersionLayers,
  selectCurrentVersionStatus,
  useGetIconsQuery,
  useGetLayersQuery,
  useGetVersionConfigQuery,
  useGetVersionQuery,
  useGetVersionsQuery,
  useGetVersionStatusQuery,
  useLazyGetVersionCostBinRangesQuery,
} from "fond/api";
import { AsyncOperationState } from "fond/async/redux";
import { HistoryProvider } from "fond/history";
import useEntityViews from "fond/hooks/useEntityViews";
import { useUpdateDocTitle } from "fond/hooks/useUpdateDocTitle";
import ImportModal from "fond/import/modal";
import { commentsConfig } from "fond/layers";
import LayoutProvider from "fond/layout/LayoutProvider";
import MapProvider from "fond/map/MapProvider";
import { NavigateError } from "fond/navigation/NavigateError";
import { useNavigateError } from "fond/navigation/useNavigateError";
import { PageMenu } from "fond/project/pageMenu";
import ProjectMapPage from "fond/project/ProjectMapPage";
import {
  closeImportModal,
  closeProject,
  dismissLoadProjectError,
  getCurrentProject,
  loadProject,
  Modals,
  setLayersVisibility,
  setVersion,
} from "fond/project/redux";
import { getComments } from "fond/redux/comments";
import TopBar from "fond/topBar/TopBar";
import { Project, projectTypeLabelMapping, Store } from "fond/types";
import { costToServeRanges } from "fond/utils/costToServeTransformers";
import { useAppDispatch } from "fond/utils/hooks";
import { Actions as PermissionActions, permissionCheck } from "fond/utils/permissions";
import { BlockSpinner, ErrorModal } from "fond/widgets";

const ProjectPage: React.FC = () => {
  useEntityViews();
  const dispatch = useAppDispatch();
  const navigateError = useNavigateError();
  const { uuid: projectId } = useParams<"uuid">();
  const project = useSelector((state: Store): Project => getCurrentProject(state.project));
  const currentStatus = useSelector((state: Store) => selectCurrentVersionStatus(state));
  const versionId = useSelector((state: Store) => state.project.versionId);
  const previousVersionId = usePrevious(versionId);
  const loadProjectStatus = useSelector((state: Store) => state.project.loadProjectStatus);
  const loadDataStatus = useSelector((state: Store) => state.project.loadDataStatus);
  const modal = useSelector((state: Store) => state.project.modal);
  const [loaded, setLoaded] = useState(false);
  const {
    data: versions,
    isError: isErrorVersions,
    isSuccess: versionDataLoaded,
    refetch: refetchVersions,
  } = useGetVersionsQuery(projectId as string, { skip: !projectId });
  const { data: version } = useGetVersionQuery(versionId, { skip: !versionId });
  const { data: status } = useGetVersionStatusQuery(versionId, { skip: !versionId });
  const [getCostBinRanges] = useLazyGetVersionCostBinRangesQuery();
  const { isSuccess: iconsLoaded } = useGetIconsQuery(undefined);
  const layerConfigs = useSelector((state: Store) => selectAllVersionLayers(state, versionId));
  const groupConfigs = useSelector((state: Store) => selectAllVersionGroupConfigs(state, versionId));
  const { data: config, isSuccess: configDataLoaded } = useGetVersionConfigQuery(versionId, { skip: !versionId });
  const { isSuccess: layersLoaded } = useGetLayersQuery(versionId, { skip: !versionId });

  const allLoaded = loaded && layersLoaded && iconsLoaded && versionDataLoaded && configDataLoaded;

  const showDesignPanel =
    !project?.HasCustomLayerConfig && !status?.HasSolution && permissionCheck(project?.Permission?.Level, PermissionActions.PROJECT_EDIT);
  const MAX_DISPLAY_BINS_COUNT = 5;

  const windowTitle = useMemo(() => {
    const projectTypeTitle = `${projectTypeLabelMapping[project?.SubType]} Project`;
    return `${project?.ProjectName || "Untitled Project"} - ${version?.Name || "unknown version"} - ${projectTypeTitle}`;
  }, [project?.ProjectName, project?.SubType, version?.Name]);
  useUpdateDocTitle(windowTitle);

  useEffect(() => {
    return () => {
      dispatch(closeProject());
    };
  }, [dispatch]);

  // Ensure the version root configuration always contains the comments Group, Layer, and Styles.
  useEffect(() => {
    // Configuration IDs returned by the backend are always UUIDs, so there's no way that a user could have
    // created a configuration having `projectComments` as its ID.
    if (config && !config.MapChildren.some((id) => config.Data.entities[id]?.ID === "projectComments")) {
      injectIntoVersionConfig(dispatch, versionId, commentsConfig);
    }
  }, [dispatch, config, versionId]);

  /**
   * Once a design is complete add the cost to serve layers
   */
  useEffect(() => {
    if (version && configDataLoaded && currentStatus?.Status === "Complete") {
      getCostBinRanges(version.ID).then((result) => {
        if (result.data) {
          const transform = costToServeRanges(result.data, MAX_DISPLAY_BINS_COUNT);
          // Only add the layers if the bin ranges contained a value layer configuration
          // e.g. costs & ranges were generated during the design.
          if (transform.some((entity) => entity.Type === "LAYER")) {
            injectIntoVersionConfig(dispatch, version.ID, transform);
          }
        }
      });
    }
  }, [currentStatus?.Status, configDataLoaded, dispatch, version, getCostBinRanges]);

  /**
   * When the layers and group load we inject any missing items into
   * the layer visibility record to allow for correct layer visibility toggling.
   */
  useEffect(() => {
    if (layerConfigs && groupConfigs) {
      dispatch(setLayersVisibility({ projectId, layerConfigs, groupConfigs }));
    }
  }, [dispatch, layerConfigs, groupConfigs, projectId]);

  /**
   * View the latest version whenever the versions change.
   */
  useEffect(() => {
    if (projectId && versions && versions.ids?.length > 0) {
      dispatch(setVersion(versions.ids[0]));
    }
  }, [dispatch, projectId, versions]);

  /**
   * Whenever the version changes, reload the project and comments.
   *
   * This is necessary for planner projects where version-state is stored in a project-scoped manner.
   */
  useEffect(() => {
    if (projectId && versionId) {
      const forceRefetch = versionId !== previousVersionId || layerConfigs.length === 0;
      const load = async () => {
        if (loaded) {
          // No need to fetch comments again.
          await dispatch(loadProject({ uuid: projectId, forceRefetch: forceRefetch }));
        } else {
          await Promise.all([dispatch(loadProject({ uuid: projectId, forceRefetch: forceRefetch })), dispatch(getComments(projectId))]);
          setLoaded(true);
        }
      };
      load();
    }
  }, [dispatch, loaded, previousVersionId, layerConfigs, projectId, versionId]);

  /**
   * Callback for handling retry after an error during loading
   */
  const handleOnError = () => {
    refetchVersions();
    dispatch(dismissLoadProjectError(navigateError));
  };

  // If project could not be found, there are no versions, or fetching of versions failed due to user having no access to project, we redirect
  if (loadProjectStatus === AsyncOperationState.notFound || (versions && versions.ids?.length === 0) || isErrorVersions) {
    return <NavigateError to="/404" resourceType="PROJECT" />;
  }

  return (
    <MapProvider>
      <Box display="flex" height="100%" width="100%" flexDirection="column">
        {loadProjectStatus === AsyncOperationState.failure && (
          <ErrorModal message="There was a problem loading the project. Please try again." onClose={handleOnError} />
        )}
        {loadDataStatus === AsyncOperationState.failure && (
          <ErrorModal message="There was a problem loading the project data. Please try again." onClose={handleOnError} />
        )}
        {allLoaded ? (
          <HistoryProvider>
            <LayoutProvider type="project" showDesignPanel={showDesignPanel}>
              <Box>
                <TopBar />
                <PageMenu type="project" />
              </Box>
              <ProjectMapPage />
            </LayoutProvider>
          </HistoryProvider>
        ) : (
          <Box display="flex" height="100%">
            <BlockSpinner />
          </Box>
        )}
        {modal === Modals.import && <ImportModal onClose={() => dispatch(closeImportModal())} />}
      </Box>
    </MapProvider>
  );
};

export default ProjectPage;
