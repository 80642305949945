import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useSnackbar } from "notistack";

import {
  draftSlice,
  getUserPreferenceValue,
  layersSlice,
  selectAllDraftConfigsInOrder,
  selectAllDraftGroups,
  selectAllDraftStyles,
  selectDraftData,
  selectVersionMlcId,
  useCreateDraftMutation,
  useGetIconsQuery,
  useGetVersionQuery,
  useGetVersionsQuery,
  usePublishDraftMutation,
  useUpdateUserPreferencesMutation,
  versionsSlice,
} from "fond/api";
import { AsyncOperationState } from "fond/async/redux";
import useEntityViews from "fond/hooks/useEntityViews";
import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import { getInitialLayerToggles } from "fond/layers/functions";
import SearchField from "fond/map/Field/SearchField";
import Map from "fond/map/Map";
import MapButtons from "fond/map/MapButtons";
import MapProvider from "fond/map/MapProvider";
import MapStyleToggle from "fond/map/MapStyleToggle";
import mixpanel from "fond/mixpanel";
import { NavigateError } from "fond/navigation/NavigateError";
import { getCurrentProject } from "fond/project";
import { closeProject, loadProject, setVersion } from "fond/project/redux";
import { reset } from "fond/redux/styles";
import { Editor, FeatureStyleHandler, TopBar } from "fond/styleEditor";
import { StyleEditor as TourDialog } from "fond/tours";
import { Store, UserPreferenceKey, UserPreferenceSubKey } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { Actions } from "fond/utils/permissions";
import { BlockSpinner } from "fond/widgets";

import DiscardDraftConfirmationModal from "./DiscardDraftConfirmationModal";

import "../fonts.scss";
import { Container, MapContainer, Panel } from "./styleEditorPage.styles";

type SaveResult = { id: string; status: "success" | "failure"; message?: string };

const StyleEditorPage: React.FC = () => {
  useEntityViews();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { uuid: projectId } = useParams<"uuid">();
  const versionId = useSelector((state: Store) => state.project.versionId);
  const loadProjectStatus = useSelector((state: Store) => state.project.loadProjectStatus);
  const selectedId = useSelector((state: Store) => state.styles.settings.selectedId);
  const draft = useSelector((state: Store) => selectDraftData(state));
  const layerConfigs = useSelector((state: Store) => selectAllDraftConfigsInOrder(state));
  const groupConfigs = useSelector((state: Store) => selectAllDraftGroups(state));
  const styles = useSelector((state: Store) => selectAllDraftStyles(state));
  const showTourDialog = useSelector((state: Store) => getUserPreferenceValue(state, UserPreferenceKey.UI_TOUR, UserPreferenceSubKey.STYLE_EDITOR));
  const hasCustomLayerConfig = useSelector((state: Store) => getCurrentProject(state.project)?.HasCustomLayerConfig);
  const permissionLevel = useSelector((state: Store) => getCurrentProject(state.project)?.Permission.Level);
  const canEditStyles = usePermissionCheck(Actions.PROJECT_EDIT_STYLES, permissionLevel);
  const versionConfigId = useSelector(selectVersionMlcId);

  const [loaded, setLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isConfirmingRevert, setIsConfirmingRevert] = useState(false);
  const { data: versions } = useGetVersionsQuery(projectId as string, { skip: !projectId });
  const { data: version } = useGetVersionQuery(versionId, { skip: !versionId });
  const { isSuccess: iconsLoaded } = useGetIconsQuery(undefined);
  const [triggerLayerRequest] = layersSlice.endpoints.getLayers.useLazyQuery();
  const [triggerVersionRequest] = versionsSlice.endpoints.getVersion.useLazyQuery();
  const [triggerDraftRequest] = draftSlice.endpoints.getDraft.useLazyQuery();
  const [createDraft] = useCreateDraftMutation();
  const [updatePreference] = useUpdateUserPreferencesMutation();
  const [publishDraft] = usePublishDraftMutation();
  const navigate = useNavigate();
  const allLoaded = loaded && iconsLoaded;

  useEffect(() => {
    mixpanel.track("Style Editor : Loaded");
    return () => {
      dispatch(closeProject());
      dispatch(reset());
      // TODO: Re-implement this with the new route
      // styleSlice.endpoints.getStyles.initiate(versionId, { forceRefetch: true });
    };
  }, []);

  useLayoutEffect(() => {
    if (loaded && (!hasCustomLayerConfig || !canEditStyles)) {
      navigate(`/project/${projectId}`);
    }
  }, [canEditStyles, hasCustomLayerConfig, loaded, navigate, projectId]);

  /**
   * A flat list of layers & sublayers indicating their current view status.
   * Unlike normal project view, for the style editor we ignore any of the current users
   * layer visibility settings they may have set.
   */
  const layerView = useMemo(() => {
    if (layerConfigs) {
      return getInitialLayerToggles({ layers: layerConfigs, groups: groupConfigs });
    }
    return {};
  }, [layerConfigs, groupConfigs]);

  useEffect(() => {
    if (versionId) {
      triggerLayerRequest(versionId);
      triggerVersionRequest(versionId);
    }
  }, [versionId]);

  useEffect(() => {
    const getDraft = async () => {
      try {
        await triggerDraftRequest(versionConfigId).unwrap();
      } catch {
        await createDraft(versionConfigId).unwrap();
        triggerDraftRequest(versionConfigId);
      }
    };
    if (versionConfigId) {
      getDraft();
    }
  }, [versionConfigId]);

  /**
   * When versions load we need to:
   * - set the latest version to the one set for viewing.
   * - load the project
   *
   * Version information must be present before we loadProject() as
   * it relies on knowing the current versionId to use.
   */
  useEffect(() => {
    if (versions && versions.ids?.length > 0) {
      dispatch(setVersion(versions.ids[0]));

      /**
       * We wait for the required project data to load prior to displaying
       * the project routes.
       */
      const load = async () => {
        await dispatch(loadProject({ uuid: projectId }));
        setLoaded(true);
      };

      if (!loaded) load();
    }
  }, [versions, projectId]);

  const handleOnPublish = async () => {
    setIsSaving(true);
    try {
      await publishDraft({ draftId: draft.ID, versionId: versionId }).unwrap();
      setIsSaving(false);
      enqueueSnackbar("Changes successfully saved.", { variant: "success" });
      /**
       * User needs to be redirected away from style editor page upon publish
       * since the draft published is now deleted in the db.
       * A new draft is needed to make new changes, and it can only be
       * generated on user's revisit to style editor page.
       */
      navigate(`/project/${projectId}`);
    } catch {
      enqueueSnackbar("There was a problem publishing some of your changes...", {
        variant: "error",
        action: (
          <Button onClick={handleOnPublish} color="inherit">
            Retry
          </Button>
        ),
      });
      setIsSaving(false);
    }
  };

  const onTourCompletion = () => {
    updatePreference({ Key: UserPreferenceKey.UI_TOUR, Subkey: UserPreferenceSubKey.STYLE_EDITOR, Value: false });
  };

  // If project could not be found or there are no versions we redirect
  if (loadProjectStatus === AsyncOperationState.notFound || (versions && versions.ids?.length === 0)) {
    return <NavigateError to="/404" resourceType="PROJECT" />;
  }

  return (
    <MapProvider>
      {allLoaded ? (
        <Container>
          <TopBar onPublish={handleOnPublish} isSaving={isSaving} onRevertButtonClick={() => setIsConfirmingRevert(true)} />
          <Box display="flex" flexGrow={1}>
            <Panel>
              <Editor />
            </Panel>
            <MapContainer expanded={selectedId !== ""}>
              <Map editMode="styles" layerConfigs={layerConfigs} styles={styles} layerView={layerView}>
                <Box className="left-sidebar-section">
                  <MapStyleToggle />
                </Box>
                <Box className="right-sidebar-section">
                  <Box display="flex" overflow="hidden" pb={1} flexGrow={1}>
                    <SearchField />
                  </Box>
                  <MapButtons version={version} />
                </Box>
                <FeatureStyleHandler />
              </Map>
            </MapContainer>
          </Box>
          {showTourDialog && <TourDialog onComplete={onTourCompletion} />}
          {isConfirmingRevert && <DiscardDraftConfirmationModal onClose={() => setIsConfirmingRevert(false)} draftId={draft.ID} />}
        </Container>
      ) : (
        <Box display="flex" height="100%">
          <BlockSpinner />
        </Box>
      )}
    </MapProvider>
  );
};

export default StyleEditorPage;
