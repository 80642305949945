import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { ColDef, ValueFormatterParams, ValueGetterParams } from "@ag-grid-community/core";
import { Check, Close } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

import { SEARCH_KEY } from "fond/constants";
import { useStarred } from "fond/projects/ItemMenu/hooks/useStarred";
import { ResourceEntity, Store } from "fond/types";
import { formatFractionPercent, formatNumber } from "fond/utils/number";
import { dateComparator, dateFilterComparator, dateValueFormatter } from "fond/widgets/AgGrid";

import EntityTypeCellRenderer from "./ProjectList/EntityCellRenderer";
import RowMenuCellRenderer from "./ProjectList/RowMenuCellRenderer";
import StatusCellRenderer from "./ProjectList/StatusCellRenderer";

type UseProjectsTableReturn = {
  columns: ColDef[];
};

interface IProps {
  openRowMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, entity?: ResourceEntity): void;
}

const useProjectsTable = ({ openRowMenu }: IProps): UseProjectsTableReturn => {
  const { search, pathname } = useLocation();
  const { filters, account } = useSelector((state: Store) => state.projects);
  const currentUsername = useSelector((state: Store) => state.cognito.user?.username);
  const { starred } = useStarred();

  const isSearchPage = search.startsWith(`?${SEARCH_KEY}=`);
  const isStarred = useCallback((id: string) => starred.includes(id), [starred]);
  const isCityPlannerFolder = pathname.startsWith("/cities/");
  const isCityReportFolder = pathname.startsWith("/cityReports/");

  /**
   * Return a filtered set of grid colums based on current view (e.g. workspace, shared, recent, etc...)
   *
   * @param cols - All grid columns
   * @returns {ColDef[]} Column definitions for a grid
   */
  const filterColumns = useCallback(
    (columns: ColDef[]) => {
      let cols = columns;

      if (!isCityPlannerFolder) {
        cols = cols.filter((col) => col.field !== "MultiProjectArea.ImportStatus");
      }

      if (!isCityReportFolder) {
        cols = cols.filter((col) => col.field && !["Report.Status", "Report.Npv", "Report.Cost", "Report.Irr"].includes(col.field));
      }

      if (isCityReportFolder) {
        cols = cols.filter((col) => col.field && !["LastModifiedBy", "LastModified", "EntityType"].includes(col.field));
      }

      if (filters === "recent") return cols;

      // Remove 'LastViewed' column if view is 'shared' or 'search' related
      if ((filters === "shared" && !account) || isSearchPage) {
        return cols.filter((col) => col.field !== "LastViewed");
      }

      // Remove 'Account' and 'LastViewed' columns
      return cols.filter((col) => col.field && !["Account.Name", "LastViewed"].includes(col.field));
    },
    [account, filters, isSearchPage, isCityPlannerFolder]
  );

  /**
   * Return set of grid colums based on current view (e.g. workspace, shared, recent, etc...)
   *
   * @param cols - All grid columns
   * @returns Column definitions for a grid
   */
  const sortColumns = (cols: ColDef[]): ColDef[] => {
    const sortedColumns = cols;
    const lastViewed = cols.find((col) => col.field === "LastViewed");
    const lastModified = cols.find((col) => col.field === "LastModified");

    if (lastViewed) {
      lastViewed.sort = "desc";
      delete lastModified?.sort;
    }
    if (!lastViewed && lastModified) {
      lastModified.sort = "desc";
    }

    return sortedColumns;
  };

  const reportStatusEntries = {
    PENDING: null, // Should not be observable through the API
    IN_PROGRESS: {
      text: "Generating",
      icon: <CircularProgress size={18} color="info" />,
    },
    COMPLETE: {
      text: "Generated",
      icon: <Check />,
    },
    ERROR: {
      text: "Error",
      icon: <Close />,
    },
  };

  const importStatusEntries = {
    PENDING: null, // Should not be observable through the API
    IN_PROGRESS: {
      text: "Creating",
      icon: <CircularProgress size={18} color="info" />,
    },
    COMPLETE: {
      text: "Created",
      icon: <Check />,
    },
    ERROR: {
      text: "Error",
      icon: <Close />,
    },
  };

  const allColumns: ColDef[] = useMemo(
    () => [
      {
        field: "Name",
        headerName: "Title",
        flex: 3,
        minWidth: 200,
        cellRenderer: EntityTypeCellRenderer,
        cellRendererParams: {
          currentUsername: currentUsername,
          isStarred: isStarred,
        },
        showDisabledCheckboxes: false,
        valueGetter: (params: ValueGetterParams<ResourceEntity>) =>
          params.data?.EntityType === "folder" || params.data?.EntityType === "report" ? params.data?.Name : params.data?.ProjectName,
        headerCheckboxSelection: !isSearchPage,
        headerCheckboxSelectionCurrentPageOnly: true,
        // Note that passing checkboxSelection = false will result in a hidden checkbox
        // being rendered.  When we want to fully disable checkbox selection no prop value can be passed
        // at all, hence the destructuring.
        ...(!isSearchPage ? { checkboxSelection: true } : {}),
      },
      {
        field: "MultiProjectArea.ImportStatus",
        cellRendererSelector: ({ data }) => {
          if (data?.EntityType === "project" && data?.MultiProjectArea?.ImportStatus !== null) {
            return {
              params: { status: data.MultiProjectArea.ImportStatus, statusEntries: importStatusEntries },
              component: StatusCellRenderer,
            };
          }
          return undefined;
        },
        headerName: "Status",
        width: 125,
      },
      {
        field: "Account.Name",
        headerName: "Account",
        flex: 1,
      },

      {
        field: "Report.Status",
        headerName: "Status",
        width: 150,
        cellRendererSelector: ({ data }) => {
          if (data?.EntityType === "report" && data?.Status !== null) {
            return {
              params: { status: data.Status, statusEntries: reportStatusEntries },
              component: StatusCellRenderer,
            };
          }
          return undefined;
        },
      },
      {
        field: "Report.Npv",
        headerName: "NPV",
        width: 150,
        valueGetter: (params: ValueGetterParams<ResourceEntity>) => {
          if (params.data?.EntityType === "report" && params.data?.MultiReport) return formatNumber(params.data?.Npv);

          return "-";
        },
      },
      {
        field: "Report.Cost",
        headerName: "Cost",
        width: 150,
        valueGetter: (params: ValueGetterParams<ResourceEntity>) => {
          if (params.data?.EntityType === "report" && params.data?.MultiReport) return formatNumber(params.data?.NetCost);

          return "-";
        },
      },
      {
        field: "Report.Irr",
        headerName: "IRR",
        width: 150,
        valueGetter: (params: ValueGetterParams<ResourceEntity>) => {
          if (params.data?.EntityType === "report" && params.data?.MultiReport) return formatFractionPercent(params.data?.Irr);

          return "-";
        },
      },
      {
        field: "EntityType",
        headerName: "Type",
        width: 150,
        valueGetter: (params: ValueGetterParams<ResourceEntity>) => {
          if (params.data?.EntityType === "folder" && params.data?.MultiProject) return "City Planner";
          if (params.data?.EntityType === "folder" && params.data?.MultiReport) return "City Planner Report";
          if (params.data?.EntityType === "folder") return "Folder";
          if (params.data?.EntityType === "report" && params.data.Type === "financial_analytics_imported") return "Services Report";
          if (params.data?.EntityType === "report" && params.data?.MultiReport) return "City Subarea Report";
          if (params.data?.EntityType === "report") return "Report";
          if (params.data?.EntityType === "project" && params.data.SubType === "planner") return "Planner Project";
          if (params.data?.EntityType === "project" && params.data.SubType === "collaboration") return "Collaboration Project";
          if (params.data?.EntityType === "project" && params.data.SubType === "design") return "Design Project";

          return "-";
        },
      },
      {
        field: "LastModifiedBy",
        headerName: "Last modified by",
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) => {
          let username = params.data?.LastModifiedBy || params.data?.Creator;
          if (params.data?.EntityType === "report") username = username.Email;
          return username === currentUsername ? "me" : username;
        },
      },
      {
        comparator: dateComparator,
        field: "LastModified",
        headerName: "Last modified",
        width: 170,
        filter: "agDateColumnFilter",
        filterParams: {
          debounceMs: 500,
          suppressAndOrCondition: true,
          comparator: dateFilterComparator,
        },
        valueFormatter: dateValueFormatter,
      },
      {
        comparator: dateComparator,
        field: "LastViewed",
        headerName: "Last viewed",
        width: 170,
        filter: "agDateColumnFilter",
        filterParams: {
          debounceMs: 500,
          suppressAndOrCondition: true,
          comparator: dateFilterComparator,
        },
        valueFormatter: dateValueFormatter,
      },
      {
        field: "menu",
        headerName: "",
        cellRenderer: RowMenuCellRenderer,
        cellRendererParams: {
          onClick: openRowMenu,
        },
        sortable: false,
        resizable: false,
        type: "rightAligned",
        width: 74,
      },
    ],
    [currentUsername, isStarred, openRowMenu]
  );

  const filteredColumns = useMemo(() => filterColumns(allColumns), [filterColumns, allColumns]);
  const filteredAndSortedColumns = sortColumns(filteredColumns);

  return { columns: filteredAndSortedColumns };
};

export default useProjectsTable;
