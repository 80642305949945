import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import { Feature, MultiPolygon } from "geojson";
import partition from "lodash/partition";

import { selectVersionConfig, useGetMultiProjectQuery } from "fond/api";
import { useDataset } from "fond/hooks/useDataset";
import SourceAndLayers from "fond/map/SourceAndLayers";
import { Store } from "fond/types";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { isVisible } from "fond/utils/configurations";

interface IProps {
  layerConfigs: Array<LayerConfig | SublayerConfig>;
  styles: LayerStyle[];
  layerView: {
    [key: string]: boolean;
  };
}

const MapContent: React.FC<IProps> = ({ layerConfigs, layerView, styles }: IProps) => {
  const versionId = useSelector((state: Store) => state.project.versionId);
  const configData = useSelector((state: Store) => selectVersionConfig(state, versionId));
  const { data: multiProject } = useGetMultiProjectQuery(versionId ?? skipToken);

  const checkVisibility = (id: string, view: { [layerId: string]: boolean }) => isVisible(configData, { id: id, layerView: view });
  const { dataset, tileUrl } = useDataset({ key: "fcc-2024", accountId: multiProject?.Account.ID ?? null });

  const features = useMemo(() => {
    const polygons: Feature<MultiPolygon>[] = [];

    // Add the sub area boundaries
    multiProject?.Areas.forEach((area) => {
      polygons.push({
        type: "Feature",
        properties: {
          id: area.ID,
          boundaryId: area.ID,
          name: area.Name,
          score: area.Score,
        },
        geometry: area.Boundary,
      });
    });

    return polygons;
  }, [multiProject]);

  // The configuration data needs to be jumbled up together for e.g. the legend; however SourceAndLayers/mapbox doesn't
  // like us providing the same layer configuration to multiple sources, so separate those configs here.
  const [fccLayerConfigs, otherLayerConfigs] = partition(layerConfigs, ({ ID }) => ID.startsWith("fcc-"));
  const [fccStyles, otherStyles] = partition(styles, ({ ID }) => ID.startsWith("fcc-"));

  return (
    <>
      {dataset && (
        <SourceAndLayers
          sourceId={dataset.Key}
          source={{
            type: "vector",
            tiles: [tileUrl],
            promoteId: "id",
            minzoom: dataset.TileCollection?.MinZoom,
            maxzoom: dataset.TileCollection?.MaxZoom,
          }}
          styles={fccStyles}
          layers={fccLayerConfigs}
          layerVisibilities={layerView}
          isVisible={checkVisibility}
        />
      )}
      <SourceAndLayers
        sourceId="multiProject-source"
        source={{
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: features,
          },
          promoteId: "id",
        }}
        styles={otherStyles}
        layers={otherLayerConfigs}
        layerVisibilities={layerView}
        isVisible={checkVisibility}
      />
    </>
  );
};

export default MapContent;
