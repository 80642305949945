import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Paper, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

import { selectFolderById, selectReportsByMultiReportId, useGetReportsQuery } from "fond/api";
import SearchBar from "fond/projects/SearchBar";
import { Store } from "fond/types";

import ProjectList from "../ProjectList/ProjectList";

interface RouteParams {
  folderId: string;
}

const CityReportList: React.FC = () => {
  const { folderId } = useParams<keyof RouteParams>();
  const folder = useSelector((state: Store) => (folderId ? selectFolderById(state, folderId) : undefined));
  const { isLoading, isFetching } = useGetReportsQuery(folder?.MultiReport?.ID ?? skipToken);
  const reports = useSelector((state: Store) => (folder && folder?.MultiReport && selectReportsByMultiReportId(state, folder.MultiReport.ID)) || []);
  const [searchText, setSearchText] = useState("");

  /**
   * Callback function that is passed the filter text as it changes
   */
  const handleOnFilterChange = (value: string) => {
    setSearchText(value.toUpperCase());
  };

  return (
    <>
      <Typography variant="h3" component="span" fontWeight={700} mt={4}>
        Subarea reports
      </Typography>
      <Box my={3}>
        <SearchBar onChange={handleOnFilterChange} placeholder="Filter reports" />
      </Box>
      <Paper square>
        <ProjectList searchText={searchText} items={isLoading ? null : reports} folder={folder} isLoading={isFetching} />
      </Paper>
    </>
  );
};

export default CityReportList;
