import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import {
  ColDef,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  MenuItemDef,
  RowDataUpdatedEvent,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
  SortChangedEvent,
} from "@ag-grid-community/core";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { AgGridReact } from "@ag-grid-community/react";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { Box, CSSObject } from "@mui/material";

import { areWeTestingWithJest } from "fond/utils";

import BlockSpinner from "../BlockSpinner";

import "@ag-grid-community/styles/ag-grid.css";
import "./theme/ag-theme-compact.scss";
import "./theme/ag-theme-standard.scss";
import "./theme/ag-theme-large.scss";

interface IProps {
  /**
   * The grid works out the best width for columns
   * @default true
   */
  autoSizeColumns?: boolean;
  containerProps?: CSSObject;
  /**
   * Sets columns to adjust in size to fit the grid horizontally
   * i.e. enable responsive horizontal rendering
   */
  sizeColumnsToFit?: boolean;
  /**
   * Used to manually trigger the showLoadingOverlay. Note passing rowData = null will allow
   * AgGrid to self manage the loading overlay.
   */
  showLoadingOverlay?: boolean;
  externalFilter?: boolean;
  externalSearchText?: string;
  gridOptions?: GridOptions;
  readOnlyEdit?: boolean;
  /**
   * The row data to load & display. A null value indicates that the data is still loading.
   */
  rowData: any[] | null;
  columnDefs: ColDef[];
  variant?: "borderless" | "outlined";
  size?: "compact" | "standard" | "large";
  /**
   * Callback function to be called when the grid is ready
   */
  onGridReady?(event: GridReadyEvent): void;
  onSortChanged?(event: SortChangedEvent): void;
  onFilterChanged?(event: FilterChangedEvent): void;
  /**
   * Callback function to be called when row selection changes
   */
  onSelectionChanged?(event: SelectionChangedEvent): void;
  /**
   * The client has set new data into the grid using api.setRowData() or by changing the rowData bound property.
   */
  onRowDataUpdated?(event: RowDataUpdatedEvent): void;
  /**
   * Callback function to be called when row is double clicked
   */
  onRowDoubleClicked?(event: RowDoubleClickedEvent): void;
  /**
   * Optional function that can be used to set the Context menu items.
   * This is called each time the user right clicks on a row.
   */
  getContextMenuItems?(params: GetContextMenuItemsParams): (string | MenuItemDef)[];
  /**
   * Callback function that determines if a row should be visible
   * based on an external filter (e.g. Search Input Field)
   */
  doesExternalFilterPass?(node: IRowNode): boolean;
  onFirstDataRendered?(params: FirstDataRenderedEvent): void;
}

const AgGrid: React.FC<IProps> = ({
  autoSizeColumns = true,
  containerProps = { height: "100%", width: "100%" },
  columnDefs,
  gridOptions,
  doesExternalFilterPass,
  getContextMenuItems,
  onGridReady,
  onSelectionChanged,
  onRowDataUpdated,
  onRowDoubleClicked,
  onFirstDataRendered,
  onFilterChanged,
  onSortChanged,
  externalFilter = false,
  externalSearchText = "",
  readOnlyEdit = false,
  rowData,
  showLoadingOverlay,
  sizeColumnsToFit = false,
  variant = "borderless",
  size = "standard",
}: IProps) => {
  const gridApi = useRef<GridApi | null>();
  const wrapperRef = useRef<HTMLElement | null>(null);

  const modules = useMemo(
    () => [
      RowGroupingModule,
      FiltersToolPanelModule,
      ClipboardModule,
      MenuModule,
      ClientSideRowModelModule,
      ServerSideRowModelModule,
      SetFilterModule,
      CsvExportModule,
      ExcelExportModule,
      RangeSelectionModule,
      RichSelectModule,
    ],
    []
  );

  // Column Api initalisation
  useEffect(() => {
    if (autoSizeColumns) {
      gridApi.current?.autoSizeAllColumns();
    }
  }, [gridApi.current]);

  useEffect(() => {
    // Call the filter changed event as the external search has changed
    gridApi.current?.onFilterChanged();
  }, [externalSearchText]);

  useEffect(() => {
    if (showLoadingOverlay) {
      gridApi.current?.showLoadingOverlay();
    } else if (showLoadingOverlay === false) {
      gridApi.current?.hideOverlay();
    }
  }, [showLoadingOverlay]);

  const defaultOptions: GridOptions = useMemo(
    () => ({
      // Sets default column values (These can be set individually in column definitions)
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        enableRowGroup: true,
      },
      getContextMenuItems: getContextMenuItems,
      doesExternalFilterPass: doesExternalFilterPass,
      sideBar: {
        toolPanels: [
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
            toolPanelParams: {
              suppressExpandAll: true,
              suppressFilterSearch: true,
            },
          },
        ],
      },
      popupParent: document.querySelector("body") || undefined,
      reactiveCustomComponents: true,
      suppressContextMenu: false,
      suppressCellFocus: true,
      suppressDragLeaveHidesColumns: true,
      rowGroupPanelShow: "always",
      rowSelection: "multiple",
      // AgGrid currently does not remove the loadingOverlay when running jest tests
      // if the data loaded is an empty array (indicating it should show the empty rows component)
      loadingOverlayComponent: areWeTestingWithJest() ? undefined : BlockSpinner,
    }),
    []
  );

  /**
   * The grid has initialised
   */
  const handleOnGridReady = (event: GridReadyEvent) => {
    gridApi.current = event.api;

    // To support popups within floating windows we need to set the popup parent
    event.api.setGridOption("popupParent", handleGetDocument().body);

    onGridReady?.(event);
  };

  const handleOnFirstDataRendered = (event: FirstDataRenderedEvent) => {
    if (sizeColumnsToFit) {
      gridApi.current?.sizeColumnsToFit();
    }
    onFirstDataRendered?.(event);
  };

  const handleOnRowDataUpdated = (event: RowDataUpdatedEvent) => {
    onRowDataUpdated?.(event);
  };

  const handleOnFilterChanged = (event: FilterChangedEvent) => {
    onFilterChanged?.(event);
  };

  const handleOnSortChanged = (event: SortChangedEvent) => {
    onSortChanged?.(event);
  };

  const options = { ...defaultOptions, ...gridOptions };

  /**
   * isExternalFilterPresent is called exactly once every time the grid senses a filter change.
   * It should return true if external filtering is active, otherwise false.
   * If you return true, then doesExternalFilterPass() will be called while filtering,
   * otherwise doesExternalFilterPass() will not be called.
   */
  const isExternalFilterPresent = useCallback(() => {
    return externalFilter && (options.rowModelType === "serverSide" ? false : externalSearchText !== undefined || externalSearchText !== "");
  }, [externalFilter, externalSearchText, options.rowModelType]);

  /**
   * Allows overriding what document is used. Currently used by Drag and Drop.
   * We need to specify this to support loading the grid inside a floating window.
   */
  const handleGetDocument = (): Document => {
    return wrapperRef.current?.ownerDocument || window.document;
  };

  return (
    <Box sx={containerProps} className={`ag-theme-${size} ${variant}`} data-testid="ag-grid" ref={wrapperRef}>
      <AgGridReact
        gridOptions={options}
        rowData={rowData}
        columnDefs={columnDefs}
        isExternalFilterPresent={isExternalFilterPresent}
        getChartToolbarItems={() => []}
        onGridReady={handleOnGridReady}
        onSelectionChanged={onSelectionChanged}
        onRowDoubleClicked={onRowDoubleClicked}
        onRowDataUpdated={handleOnRowDataUpdated}
        onFirstDataRendered={handleOnFirstDataRendered}
        onFilterChanged={handleOnFilterChanged}
        onSortChanged={handleOnSortChanged}
        getDocument={handleGetDocument}
        readOnlyEdit={readOnlyEdit}
        modules={modules}
      />
    </Box>
  );
};

export default AgGrid;
