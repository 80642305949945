import { NavigateOptions, useNavigate as useReactRouterNavigate } from "react-router-dom";

import { ErrorNavigationState } from "./types";

/**
 * Navigate to an error page with the provided state.
 *
 * All params are optional.
 *
 * @param path - the error page to navigate to, defaults to "/error"
 * @param state - the state to set on the new location.
 * @param options - any navigation options to use {@see NavigateOptions}
 * @returns
 */
type NavigateErrorCallback = (path?: string, state?: ErrorNavigationState, options?: NavigateOptions) => void;

/**
 *
 * @returns A function that can be used to navigate to an error page with the provided state.
 */
export function useNavigateError(): NavigateErrorCallback {
  const navigate = useReactRouterNavigate();

  return (path = "/error", state, options) => {
    navigate(path, { replace: true, state: state, ...options });
  };
}
