import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Button } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";

import {
  useGenerateReportMutation,
  useGetProjectQuery,
  useGetReportQuery,
  useGetVersionQuery,
  useGetVersionStatusQuery,
  useUpdateReportMutation,
} from "fond/api";
import useEntityViews from "fond/hooks/useEntityViews";
import mixpanel from "fond/mixpanel";
import { useNavigateError } from "fond/navigation/useNavigateError";
import { FullReport } from "fond/types";
import { PageContent } from "fond/widgets";
import SkeletonLoadingGrid from "fond/widgets/SkeletonLoadingGrid";

import { Page } from "../constants";
import ReportNavigation from "../Navigation";
import { ReportFormData } from "../types";
import { getReportPatchFromReport, reportWindowTitle, transformReportConfigurationToReportForm, transformReportFormToReportSettings } from "../util";

import ReportSettingsForm from "./ReportSettingsForm";

const ReportPage: React.FC = () => {
  useEntityViews();
  const navigate = useNavigate();
  const navigateError = useNavigateError();
  const { enqueueSnackbar } = useSnackbar();
  const { reportId } = useParams<"reportId">();

  const [isSaving, setIsSaving] = useState(false);
  const { data: reportData, isLoading: reportIsLoading, isError } = useGetReportQuery(reportId ?? "", { skip: !reportId });
  const { data: Version, isLoading: versionsLoading } = useGetVersionQuery(reportData?.Version?.ID ?? "", { skip: !reportData?.Version?.ID });
  const { data: Project, isLoading: projectLoading } = useGetProjectQuery(Version?.Project ?? "", { skip: !Version?.Project });
  const [updateReport, { isLoading: isUpdatingReport }] = useUpdateReportMutation();
  const isReportLoading = useMemo(() => reportIsLoading || versionsLoading || projectLoading, [reportIsLoading, versionsLoading, projectLoading]);
  const isReportSaving = useMemo(() => isSaving || isUpdatingReport, [isSaving, isUpdatingReport]);
  const [report, setReport] = useState<FullReport | undefined>(reportData);
  const [generateReport, { isLoading: isReportGenerating }] = useGenerateReportMutation();
  useGetVersionStatusQuery(reportData?.Version?.ID ?? "", { skip: !reportData?.Version?.ID });

  useEffect(() => {
    setReport(reportData);
  }, [reportData]);

  if (reportId && isError) {
    navigateError("/error", { resourceType: "REPORT" });
  } else if (reportData?.Type === "financial_analytics_imported") {
    navigate(`/reports/${reportId}/overview`);
  }

  const saveReport = async (values: ReportFormData, detailsOnly?: boolean) => {
    const {
      Name,
      Description,
      VersionID: SelectedVersionID,
      MultiProjectID: SelectedMultiProjectID,
      ReportConfiguration,
    } = transformReportFormToReportSettings(values);
    setIsSaving(true);
    // ensure the UI is optimistic
    setReport(
      (prevReport) =>
        prevReport && {
          ...prevReport,
          Name: Name,
          Description: Description,
          VersionID: SelectedVersionID,
          MultiProjectID: SelectedMultiProjectID,
          ReportConfiguration: ReportConfiguration,
        }
    );

    try {
      if (!report || !reportId) {
        throw new Error("Report ID is required");
      }
      if (!SelectedVersionID && !SelectedMultiProjectID) {
        throw new Error("Version or MultiProject is required");
      }
      if (!ReportConfiguration) {
        throw new Error("No report configuration to submit");
      }

      const updatedReport = await updateReport({
        ...getReportPatchFromReport(report),
        Name: Name,
        Description: Description ?? "",
        // TODO: Uncomment this if/when Version ID is added to the report patch endpoint
        // Version: { ID: SelectedVersion.ID },
        ReportConfiguration: ReportConfiguration,
      }).unwrap();

      enqueueSnackbar("Report updated successfully", { variant: "success" });
      return updatedReport;
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar("There was a problem updating the report. Please try again.", { variant: "error" });
    } finally {
      setIsSaving(false);
    }
  };

  const handleOnSubmit = async (values: ReportFormData) => {
    const updatedReport = await saveReport(values);
    if (!updatedReport) {
      return;
    }

    try {
      await generateReport(updatedReport.ID);
      navigate(`/reports/${updatedReport.ID}/poll`);
    } catch (e) {
      Sentry.captureException(e);
      enqueueSnackbar("Failed to generate report", {
        action: (
          <Button onClick={() => handleOnSubmit(values)} color="inherit">
            Retry
          </Button>
        ),
      });
    }

    mixpanel.track("Report", "Settings", "Report re-generated", { reportId: updatedReport.ID });
  };

  const updateDetails = async (values: ReportFormData) => saveReport(values, true);

  const reportFormConfig = transformReportConfigurationToReportForm(report?.ReportConfiguration, Project?.SystemOfMeasurement);

  return (
    <PageContent
      windowTitle={reportWindowTitle("Settings", report?.Name)}
      title="Settings"
      mainContent={
        isReportLoading ? (
          <SkeletonLoadingGrid items={[{ height: 50 }, { height: 200 }, { height: 50 }, { height: 150 }, { height: 150 }, { height: 500 }]} />
        ) : (
          <ReportSettingsForm
            initialValues={{
              Name: report?.Name || "Untitled report",
              Description: report?.Description || "",
              Project: Project,
              Version: Version,
              ReportConfiguration: reportFormConfig,
            }}
            onSubmit={handleOnSubmit}
            onUpdateDetails={updateDetails}
            isLoading={isReportSaving || isReportGenerating}
            report={report}
          />
        )
      }
      navigation={<ReportNavigation page={Page.SETTINGS} isCreatingReport={report?.Status === null} />}
      testId="report-settings-page"
    />
  );
};

export default ReportPage;
